import { Skeleton } from 'ui/Skeleton/Skeleton';
import { cn } from 'utils/cn';

interface SkeletonCardProps {
  amountOfLines?: number;
  className?: string;
  id?: string;
  showImageRectangle?: boolean;
}

const SkeletonCard = ({ amountOfLines = 3, className, id, showImageRectangle = true }: SkeletonCardProps) => (
  <div className={cn('flex flex-col gap-2 rounded border bg-white p-4', className)} id={id}>
    {showImageRectangle && <Skeleton className="mb-2 h-32" />}
    {[...Array(amountOfLines)].map((_, index) => (
      <Skeleton key={index} className="h-8" />
    ))}
  </div>
);

export default SkeletonCard;
