import Loader from 'components/Layout/Loader/Loader';
import { memo, ReactNode, Ref, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { floorSlidesObject, getAmountOfSlides } from '../../../utils/sliderUtils';
import Link from '../../Link/Link';
import Typography from '../../Typography/Typography';
import ProductsSlider, { DEFAULT_SLIDES_OBJECT } from '../ProductsSlider/ProductsSlider';

import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { ARROW_TYPES } from 'constants/generic';
import { EmblaCarouselType } from 'embla-carousel';
import { useProductQuery } from 'features/product/queries';
import { shuffleArray } from 'utils/arrayUtil';
import { cn } from 'utils/cn';
import { StyledSearchCarouselArrowLeft, StyledSearchCarouselArrowRight } from './SearchProductSlider.styled';

interface SearchProductSliderProps {
  button?: { text?: string; url?: string };
  callToActionComponent?: ReactNode;
  className?: string;
  productSliderRef?: Ref<EmblaCarouselType>;
  pushCnCToBack?: boolean;
  query: string;
  randomizeProductsOrder?: boolean;
  showAmount?: boolean;
  showOutOfStockProducts?: boolean;
  showSignings?: boolean;
  slidesObject?: SlidesObject;
  title?: string;
}
const SearchProductSlider = ({
  button,
  callToActionComponent,
  className,
  productSliderRef,
  pushCnCToBack,
  query,
  randomizeProductsOrder,
  showAmount,
  showOutOfStockProducts,
  showSignings,
  slidesObject = { lg: 4, md: 3, sm: 2, xl: 5, xs: 1, xxl: 5 },
  title,
}: SearchProductSliderProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfResults, setNumberOfResults] = useState(5);
  const { data: searchResults, isLoading } = useProductQuery({
    currentPage,
    pageSize: numberOfResults,
    pushCnCToBack,
    query,
    showOutOfStock: showOutOfStockProducts,
  });
  const searchProducts = searchResults?.products || [];
  const productsToShow = randomizeProductsOrder ? shuffleArray(searchProducts) : searchProducts;
  const amountOfProducts = searchResults?.pagination?.totalResults || productsToShow?.length;
  const totalPages = searchResults?.pagination?.totalPages;
  const slidesToShow = floorSlidesObject(slidesObject);
  const enableNextArrow = totalPages && currentPage < totalPages - 1;
  const enablePrevArrow = currentPage > 0;

  const nextArrowClick = () => {
    const nextPage = currentPage + 1;

    if (query) {
      setCurrentPage(nextPage);
    }
  };

  const prevArrowClick = () => {
    const prevPage = currentPage - 1;

    if (query) {
      setCurrentPage(prevPage);
    }
  };

  useEffect(() => {
    const updateWindowSize = () => {
      const windowSize = window.innerWidth;
      const numberOfProductSlides = getAmountOfSlides(windowSize, slidesToShow);
      if (numberOfProductSlides === 'auto') {
        setNumberOfResults(getAmountOfSlides(windowSize, DEFAULT_SLIDES_OBJECT) as number);
      } else {
        setNumberOfResults(numberOfProductSlides);
      }
    };

    updateWindowSize();

    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  if (productsToShow?.length <= 0) {
    return null;
  }

  return (
    <div className={cn('search-product-slider relative', className)}>
      <Loader position="absolute" show={isLoading} />

      {enablePrevArrow && (
        <StyledSearchCarouselArrowLeft
          disabled={currentPage === 0}
          onClick={() => prevArrowClick()}
          type={ARROW_TYPES.PREV}
        />
      )}
      {enableNextArrow && (
        <StyledSearchCarouselArrowRight
          disabled={currentPage === totalPages - 1}
          onClick={() => nextArrowClick()}
          type={ARROW_TYPES.NEXT}
        />
      )}
      <ProductsSlider
        alwaysShowArrows={false}
        alwaysHideArrows={true}
        amount={amountOfProducts}
        button={
          (button?.text && button?.url && (
            <Link url={button.url}>
              <Typography color="" fontSize={100}>
                {button.text}
              </Typography>
            </Link>
          )) ||
          callToActionComponent
        }
        isLoading={isLoading}
        products={productsToShow}
        productSliderRef={productSliderRef}
        showAmount={showAmount}
        slidesObject={slidesToShow}
        title={title}
        showSignings={showSignings}
        shouldClampSlidesToAvailable={false}
        ignoreSlideMaxWidth
      />
    </div>
  );
};

export default memo(SearchProductSlider, isEqual);
