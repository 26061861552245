import styled from 'styled-components';
import { fontSize } from '../../../../theme/functions';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import { CALL_TO_ACTION_TYPES, CallToActionType } from '../types';

interface ProductCarouselLinkProps {
  type: CallToActionType;
}

export const ProductCarouselLink = styled(ContentfulCallToAction)<ProductCarouselLinkProps>`
  font-size: ${({ type }) => type === CALL_TO_ACTION_TYPES.LINK && fontSize(100)};
`;
