import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { Spacer } from 'constants/styling';
import styled, { css } from 'styled-components';

export interface StyledSliderItemWrapperProps {
  gap?: Spacer;
}

export const StyledSliderItemWrapper = styled.div<StyledSliderItemWrapperProps>`
  height: 100%;
  padding-bottom: 0px;
`;

export const StyledSlider = styled(SliderEmbla)<{ ignoreSlideMaxWidth?: boolean }>`
  .embla__slide {
    min-width: 200px;
    ${({ ignoreSlideMaxWidth }) =>
      !ignoreSlideMaxWidth &&
      css`
        max-width: 350px;
      `}
  }
`;
